* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  transition: all 0.25s ease-out;
  user-select: none;
}

body {
  margin: 0;
  font-family: lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #146b78;
}

:root {
  --clr-light: #f0faf7;
  --clr-dark: #031c21;

  --foreground: var(--clr-dark);
  --background: var(--clr-light);
  --postBg: #fff;
  --footerBackground: #031c21;
}

.darkmode {
  --clr-dark: #031c21;
  --clr-light: #f0faf7;

  --foreground: var(--clr-light);
  --background: var(--clr-dark);
  --postBg: #031c21;
  --footerBackground: rgba(25, 49, 53, 1);
}
