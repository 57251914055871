.hero {
  min-height: 100vh;
  background-image: radial-gradient(
    farthest-side at 90% 40px,
    rgba(3, 28, 33, 0.9),
    #031c21
  );
  overflow: hidden;
  padding: 0 60px;
  padding-bottom: 20rem;
}

.latest-research,
.subscribe-cta,
.testemonials {
  background-color: var(--background);
  padding: 6.5rem 60px;
  overflow: hidden;
  border: 0;
  margin: 0;
}

.latest-research {
  padding-top: 12rem;
  position: relative;
}

.research-card-image {
  object-fit: cover;
  border-radius: 4px;

  object-position: center 50%;

  margin-top: -3rem;
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.icon:hover {
  transform: translateX(10px);
}

.link:hover .icon {
  transform: translateX(10px);
}

.cta-form {
  align-items: start;
}

/* testimonial card's image */
.testemonials {
  padding-bottom: 11rem;
  position: relative;
}

.test-image {
  object-fit: cover;
  height: 11.25rem;
  width: 11.1875rem;
  object-position: center 10%;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
}

/* footer */
.footer {
  width: 100%;
  background-color: var(--footerBackground);
  padding: 60px;
  overflow: hidden;
  z-index: 10;
}

.footer-cta {
  order: 1;
}

.footerSectionItem {
  color: rgba(140, 166, 158, 0.73);
}

.footerSectionItem:hover {
  color: #146b78;
}

@media only screen and (max-width: 990px) {
  .hero {
    min-height: auto;
    padding-bottom: 10rem;
  }

  .latest-research,
  .subscribe-cta,
  .testemonials {
    padding: 5rem 60px;
  }

  .latest-research {
    padding-top: 6rem;
  }

  .testemonials {
    padding-bottom: 8rem;
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    padding: 0 30px;
    padding-bottom: 10rem;
    padding-top: 15px;
  }

  .latest-research,
  .subscribe-cta,
  .testemonials {
    padding: 3.5rem 30px;
  }
  .subscribe-cta {
    padding-bottom: 4rem;
  }

  .footer {
    padding: 60px 30px;
  }

  .latest-research {
    padding-top: 5rem;
  }

  .testemonials {
    padding-bottom: 6rem;
  }

  .test-image {
    border-radius: 50%;
    height: 3.75rem;
    width: 3.75rem;
    object-position: center 10%;
    margin-bottom: 0;
    box-shadow: none;
  }
}

@media only screen and (max-width: 479px) {
  .hero {
    padding: 0 15px;
    padding-bottom: 10rem;
    padding-top: 15px;
  }

  .latest-research,
  .subscribe-cta,
  .testemonials {
    padding: 3rem 15px;
  }

  .subscribe-cta {
    padding-bottom: 4.5rem;
  }

  .latest-research {
    padding-top: 3rem;
  }

  .testemonials {
    padding-bottom: 5rem;
  }

  .cta-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .footer {
    padding: 60px 15px;
  }
}

@media only screen and (max-width: 309px) {
  .hero {
    padding: 0 10px;
    padding-bottom: 10rem;
  }

  .footer {
    padding: 60px 10px;
  }

  .latest-research,
  .subscribe-cta,
  .testemonials {
    padding: 2rem 10px;
  }

  .subscribe-cta {
    padding-bottom: 5rem;
  }
}
