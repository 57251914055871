.contact {
  background-color: var(--background);
  padding: 0 60px;
  padding-bottom: 11rem;
  overflow: hidden;
  position: relative;
}

.cover_image {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../assets/contact.jpg) no-repeat center center;
  background-size: cover;
  height: 30rem;
  width: 30rem;
  margin-right: -15%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 31rem;
  height: 30rem;
  gap: 2rem;
  justify-content: space-between;
  margin-right: 3rem;
  margin-left: 3rem;
  position: relative;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .contact {
    padding: 0 30px;
    padding-bottom: 120px;
    padding-top: 15px;
  }

  .contact-form {
    width: 100%;
    height: auto;
    gap: 1rem;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 5rem;
  }

  .cover_image {
    height: 20rem;
    width: 100%;
    margin-right: 0;
    margin-bottom: -7rem;
  }
}

@media only screen and (max-width: 479px) {
  .contact {
    padding: 0 15px;
    padding-bottom: 90px;
    padding-top: 15px;
  }

  .contact-form {
    gap: 1rem;
    margin-bottom: 5rem;
  }

  .cover_image {
    height: 20rem;
    width: 100%;
    margin-right: 0;
    margin-bottom: -5rem;
  }
}

@media only screen and (max-width: 365px) {
  .captcha {
    transform: scale(0.75);
    transform-origin: 0 0;
  }
}
