.blog {
  min-height: 100vh;
  background-color: var(--background);
  padding: 0 60px;
  padding-bottom: 11rem;
  overflow: hidden;
  position: relative;
}

.card-image {
  object-fit: cover;
  border-radius: 4px 4px 0 0;

  object-position: center 50%;
  margin-bottom: 0;
}

.card {
  transition: transform 0.3s ease-in-out;
  text-decoration: none;
  display: block;
  height: 100%;
}

.card:hover {
  transform: translateY(-5px);
}

.author_image,
.post_author_image,
.bottom_author_image {
  object-fit: cover;
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  object-position: center 50%;
  margin-bottom: 0;
}

.post_cover_image {
  object-fit: cover;
  object-position: center 50%;
  margin-bottom: 0;
  margin-right: auto;
  margin-left: auto;
}

.bottom_author_image {
  height: 3.75rem;
  width: 3.75rem;
  margin-right: 1.75rem;
}

/* Blog post styling */

.post {
  min-height: 100vh;
  padding-bottom: 6rem;
  background-color: var(--postBg);
}

/* Rich text styling */

.image {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-style-side,
.image-style-align-right {
  float: right;
  margin-left: 1rem;
}

.image-style-align-left {
  float: left;
  margin-right: 1rem;
}

.image-style-block-align-right {
  display: flex;
  align-items: flex-end;
}
.image-style-block-align-left {
  display: flex;
  align-items: flex-start;
}

.image_resized {
  width: 100%;
}

iframe {
  width: 100%;
}

blockquote {
  background-color: transparent;
  padding: 10px 20px;
  margin: 30px 0;
  border-left: #d3d8dc solid 3px;
  font-style: italic;
  color: #6d7d8b;
  line-height: 24px;
}

blockquote p {
  margin: 0;
}

figcaption {
  font-size: medium;
}

.rich_text p,
.rich_text li {
  font-size: 19px;
}

.rich_text li {
  text-align: start;
}

.rich_text h2 {
  font-size: 28px;
  line-height: 140%;
  text-align: start;
}
.rich_text h3 {
  font-size: 22px;
  line-height: 140%;
  text-align: start;
}

.rich_text h4 {
  font-size: 19px;
  line-height: 140%;
  text-align: start;
}

@media only screen and (max-width: 767px) {
  .blog {
    padding: 0 30px;
    padding-bottom: 6rem;
    padding-top: 15px;
  }

  .post {
    padding-bottom: 6rem;
    padding-top: 15px;
  }

  .rich_text p,
  .rich_text li {
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .blog {
    padding: 0 15px;
    padding-bottom: 6rem;
    padding-top: 15px;
  }

  .post {
    padding-bottom: 6rem;
    padding-top: 15px;
  }

  .bottom_author_image {
    height: 2.75rem;
    width: 2.75rem;
    margin-right: 1rem;
  }

  .rich_text p,
  .rich_text li {
    font-size: 17px;
  }

  .rich_text h2 {
    font-size: 25px;
  }

  .rich_text h3 {
    font-size: 20px;
  }

  .rich_text h4 {
    font-size: 18px;
  }
}
