.about {
  min-height: 100vh;
  background-color: var(--background);
  padding: 0 60px;
  padding-bottom: 160px;
  overflow: hidden;
  position: relative;
}

.about p {
  font-size: 19px;
}

@media only screen and (max-width: 767px) {
  .about {
    padding: 0 30px;
    padding-bottom: 90px;
    padding-top: 15px;
  }

  .about p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .about {
    padding: 0 15px;
    padding-bottom: 90px;
    padding-top: 15px;
  }

  .about p {
    font-size: 17px;
  }
}
